import React from 'react'
import Select from 'react-select'

import { Organizations_organizations } from '../../hasura/graphQlQueries/types/Organizations'
import { Option } from '../../lib/helpers'

interface Props {
  organization?: Organizations_organizations | null
  organizations: Organizations_organizations[]
  handleSelectOrganization: (option: Option | null) => void
}

export default function OrganizationSelect(props: Props) {
  const { handleSelectOrganization, organization, organizations } = props

  const options = organizations.map((o) => ({
    value: o.id,
    label: `${o.display_name} (${o.primary_email})`,
  }))

  return (
    <Select
      isClearable
      classNamePrefix="react-select"
      className="max-width-300px"
      placeholder="Search practice..."
      value={options.find((o) => o.value === organization?.id)}
      onChange={handleSelectOrganization}
      options={options}
    />
  )
}
